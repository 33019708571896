/* Gallery Content */

.galleryWrap {
    /* columns: 5 320px;
    column-gap: 1em;
    display: block; */
    display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-auto-rows: 250px;
	grid-auto-flow: dense;
}
.galleryWrap img{
    width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
}

.galleryWrap .single {
    cursor: pointer;
    display: flex;
	justify-content: center;
	align-items: center;
    border: 4px solid white;
}

.galleryWrap .single img {
    max-width: 100%;
    /* outline: 2px solid #fff; */
    outline-offset: -10px;
    transition: all 0.3s ease-in-out;
}

.sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    font-size: 40px;
    z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
    opacity: 1;
}

.sliderWrap .btnClose {
    top: 40px;
    right: 40px;
}

.sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
}

.sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
}

.fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}
.title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 1em;
    color: red;
}